import { useParams } from "react-router-dom";
import React, { Component } from "react";
import axios from "axios";
import Navbar from "./NavBarAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}
class EditStore extends React.Component {
  constructor(props) {
    super();
    this.state = {
      storeName: "",
      storeAddress: "",
      link: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    axios
      .get(
        "https://bejewelled-kleicha-3163f4.netlify.app/.netlify/functions/api/store/" +
          this.props.match.params.id
      )
      .then((res) => {
        this.setState({
          storeName: res.data.storeName,
          storeAddress: res.data.storeAddress,
          link: res.data.link,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onSubmit(e) {
    e.preventDefault();
    const exercise = {
      storeName: this.state.storeName,
      storeAddress: this.state.storeAddress,
      link: this.state.link,
    };

    console.log(exercise);

    axios
      .post(
        "https://bejewelled-kleicha-3163f4.netlify.app/.netlify/functions/api/store/update/" +
          this.props.match.params.id,
        exercise
      )
      .then((res) => {
        this.setState({ message: res });
      });

    alert("Store Edited!");
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="admin-form-container">
          <div className="Title-homepage">Edit this Store</div>

          <form onSubmit={this.onSubmit}>
            <div className="not-main-form">
              <div className="form-left-half">
                <div className="admin-form-box">
                  <label className="admin-form-label">
                    <FontAwesomeIcon icon="fa-solid fa-play" /> Store Name:
                  </label>
                  <input
                    type="text"
                    className="admin-form-input"
                    value={this.state.storeName}
                    onChange={(event) =>
                      this.setState({ storeName: event.target.value })
                    }
                  />
                </div>
                <div className="admin-form-box">
                  <label>
                    <FontAwesomeIcon icon="fa-solid fa-play" /> Store Address:
                  </label>
                  <input
                    type="text"
                    className="admin-form-input"
                    value={this.state.storeAddress}
                    onChange={(event) =>
                      this.setState({ storeAddress: event.target.value })
                    }
                  />
                </div>
                <div className="admin-form-box">
                  <label>
                    <FontAwesomeIcon icon="fa-solid fa-play" /> Link:
                  </label>
                  <input
                    type="text"
                    className="admin-form-input"
                    value={this.state.link}
                    onChange={(event) =>
                      this.setState({ link: event.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="admin-form-box">
              <button type="submit" value="Add" className="Submit-Button">
                <FontAwesomeIcon icon="fa-solid fa-pen" /> EDIT
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(EditStore);
