import React, { Component } from "react";
import axios from "axios";
import Navbar from "./NavBarAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class AddStore extends Component {
  constructor(props) {
    super();
    this.state = {
      storeAddress: "",
      storeName: "",
      link: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const exercise = {
      storeName: this.state.storeName,
      storeAddress: this.state.storeAddress,
      link: this.state.link,
    };

    console.log(exercise);

    axios
      .post(
        "https://bejewelled-kleicha-3163f4.netlify.app/.netlify/functions/api/store/add",
        exercise
      )
      .then((res) => {
        this.setState({ message: res });
      });
    this.setState({
      storeAddress: "",
      storeName: "",
      link: "",
    });
    alert("Store Added!");
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="admin-form-container">
          <div className="Title-homepage">Add a Store</div>
          <form onSubmit={this.onSubmit}>
            <div className="not-main-form">
              <div className="form-left-half">
                <div className="admin-form-box">
                  <label className="admin-form-label">
                    <FontAwesomeIcon icon="fa-solid fa-play" /> Store Name
                  </label>
                  <input
                    type="text"
                    className="admin-form-input"
                    value={this.state.storeName}
                    onChange={(event) =>
                      this.setState({ storeName: event.target.value })
                    }
                  />
                </div>
                <div className="admin-form-box">
                  <label>
                    <FontAwesomeIcon icon="fa-solid fa-play" /> Store Address:
                  </label>
                  <input
                    type="text"
                    className="admin-form-input"
                    value={this.state.storeAddress}
                    onChange={(event) =>
                      this.setState({ storeAddress: event.target.value })
                    }
                  />
                </div>
                <div className="admin-form-box">
                  <label>
                    <FontAwesomeIcon icon="fa-solid fa-play" /> Google Map Link:
                  </label>
                  <input
                    type="text"
                    className="admin-form-input"
                    value={this.state.link}
                    onChange={(event) =>
                      this.setState({ link: event.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="admin-form-box">
              <button type="submit" value="Add" className="Submit-Button">
                <FontAwesomeIcon icon="fa-solid fa-circle-plus" /> ADD
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default AddStore;
