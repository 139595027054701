import React, { useEffect, useState } from "react";
import Navbar from './navbar';
import axios from "axios";
import Footer from './footer';

const Store = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(
        "https://bejewelled-kleicha-3163f4.netlify.app/.netlify/functions/api/store"
      )
      .then((res) => {
        setData(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
      window.scrollTo(0,0);
  }, []);

  return (
    <div>
    <Navbar/>
    <div className='heading2' style={{'marginTop':'100px','marginBottom':'50px','textAlign':'center'}}>ALL STORE AVAILABLE</div>
      {data.map((element) => {
        return (
          <div className="card1" data-aos="zoom-in-up" key={element._id}>
             <div className='image-card'>
            <img src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVWsM4KW5OIRef1CqJyfgyK5EkPVXHEU2pvA&usqp=CAU'}  alt='Main'/>
        </div>
            <div className="detail-card">
              <div className="uper-details">
                <div className="name">{element.storeName}</div>
                <div className="tags">Address :</div>
                <div className="tags-details">{element.storeAddress}</div>
              </div>
              <a href={element.link}>
                <button className="view-more">Visit Now</button>
              </a>
            </div>
          </div>
        );
      })}
      <Footer/>
    </div>
  );
};

export default Store;
